<template>
  <div>
    <!-- /.modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <form @submit.prevent="save" class="card">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h5 class="modal-title" id="exampleModalLabel">
                <i class="fa fa-plus"></i>{{ form.id && isDetail === true ? "Detail Product" : form.id ? "Edit Product" : "Add Product" }}
              </h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group" v-if="error && !form.image" id="div_image" @click="defaultBtnImage()" style="cursor: pointer; border: 1px solid red">
                    <img :src="form.image" v-if="form.image" width="100%" height="100%" class="img-thumbnail" />
                    <img src="@assets/no-image.png" v-else width="100%" height="100%" class="img-thumbnail" alt="No Image">
                    <input type="file" accept="image/x-png,image/jpeg" id="default-btn"  @change="handleImage" :disabled="loading" hidden />
                    <div class="bg-primary text-center p-2" >
                      Upload Photo<span class="text-red">*</span>
                    </div>
                    <!-- <span v-if="error && !form.image" style="color: red;">*{{ error }}</span> -->
                  </div>
                  <div class="form-group" v-else id="div_image" @click="defaultBtnImage()" style="cursor: pointer;">
                    <img :src="form.image" v-if="form.image" width="100%" height="100%" class="img-thumbnail" />
                    <img src="@assets/no-image.png" v-else width="100%" height="100%" class="img-thumbnail" alt="No Image">
                    <input type="file" accept="image/x-png,image/jpeg" id="default-btn"  @change="handleImage" :disabled="loading" hidden />
                    <div class="bg-primary text-center p-2" >
                      Upload Photo<span class="text-red">*</span>
                    </div>
                    <!-- <span v-if="error && !form.image" style="color: red;">*{{ error }}</span> -->
                  </div>

                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="recipient-name" class="col-form-label"
                      >Product Name<span class="text-red">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      required
                      id="recipient-name"
                      v-model="form.name"
                      :disabled="loading || isDetail"
                    />
                  </div>
                  <div class="form-group">
                    <label for="message-text" class="col-form-label"
                      >SKU<span class="text-red">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      required
                      id="recipient-sku"
                      v-model="form.sku"
                      :disabled="loading || isDetail"
                    />
                  </div>
                  <div class="form-group">
                    <label for="message-text" class="col-form-label"
                      >Descriptions</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="recipient-description"
                      v-model="form.description"
                      :disabled="loading || isDetail"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button v-if="!isDetail" type="submit" class="btn btn-primary" :disabled="loading || !form.image || !form.name || !form.sku">
                <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                {{ loading ? "Saving ..." : "Save" }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <form @submit.prevent="deleteData" class="card">
                    <div class="modal-content">
                        <div class="modal-header bg-primary">
                            <h5 class="modal-title" id="deleteModalLabel"> Delete Product</h5>
                        </div>
                        <div class="modal-body">
                            <div class="col">
                                <div class="form-group">
                                    <span>Are you sure you want to delete?</span>
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <div v-if="!isDetail">
                                <button type="submit" class="btn btn-primary" :disabled="loading">
                                    <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                                    {{ loading ? 'Deleting...' : 'Delete' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark" style="font-size: 2em;">
              Products
            </h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right ">
              <!-- <li>
                <button
                  type="button"
                  class="btn btn-block btn-outline-primary btn-lg"
                >
                  <span class="fas fa-upload mr-2"></span>
                  Export
                </button>
              </li> -->
              <li class="ml-3">
                <button
                  type="button"
                  class="btn btn-block btn-primary btn-lg"
                  @click="showModal"
                >
                  <span class="fas fa-plus mr-2"></span>Add Product
                </button>
              </li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>

    <data-tables-master-data
      :config="config"
      class="container-fluid"
      id="sampleTable"
    ></data-tables-master-data>
  </div>
</template>

<script>
const { console } = window;
import DataTablesMasterData from "@components/DataTablesMasterData.vue";
export default {
  components: {
    DataTablesMasterData,
  },

  data() {
    return {
      active: false,
      errorDialog: null,
      errorText: "",
      uploadFieldName: "file",
      error: undefined,
      loading: false,
      text_validation: null,
      maxSize: 1024,
      form: {
        id: null,
        image: "",
        name: "",
        sku: "",
        description: "",
      },
      disabled: false,
      isDetail: false,
      config: {
        columns: [
          {
            title: "Gambar",
            data: "image",
            render(h) {
              return (
                '<img style="margin-top: -10px; margin-bottom: -10px" src="' +
                h +
                '" width="46px" height="48px" aligen="center">'
              );
            },
            searchable: false
          },
          {
            title: "SKU",
            data: "sku",
          },
          {
            title: "Nama",
            data: "name",
          },
          {
            title: "Action",
            data: "id",
            // render: (val) => {
            //   return `
            //   <div> 
                
            //     <span class="label label-info text-secondary mr-3"><i class="fas fa-edit"></i> </span>
            //     <span class="label label-info text-secondary mr-3"><i class="fas fa-trash"></i> </span>
            //     <span class="label label-info text-primary"><i class="fas fa-arrow-right"></i></span>

               
            //     </div>
             
            //   `;
            // },
            render: (val) => {
              return `
                <div> 
                    <a 
                        href="javascript:void" 
                        data-id="${val}" 
                        class="editor-detail" 
                        data-action="detail" 
                        style="color:#9E9E9E;margin-right:10px;"
                    >
                        <i class="fa fa-eye"></i>
                    </a>
                    <a 
                        href="javascript:void" 
                        data-id="${val}" 
                        class="editor-edit" 
                        data-action="edit"
                        style="color:#9E9E9E;margin-right:10px;"
                    >
                        <i class="fa fa-edit"></i>
                    </a>
                    <a 
                        href="javascript:void" 
                        data-id="${val}" 
                        data-action="delete" 
                        class="editor-delete" 
                        style="color:#9E9E9E;"
                    >
                        <i class="fa fa-trash"></i>
                    </a>
                </div>`
            }
          },
        ],
        "drawCallback": ( settings ) => {
          //for click detail
          $(".editor-detail").on( 'click', (e) => {
              this.getDetail($(e.target).closest('a').data('id'))
          });

          //for click edit
          $(".editor-edit").on( 'click', (e) => {
              this.getEdit($(e.target).closest('a').data('id'))
          });
          $(".editor-delete").on( 'click', (e) => {
              this.showModalDelete($(e.target).closest('a').data('id'))
          });

        },
        url: this.Api.base + "/product",
        order: [[0, "desc"]],
        filter: []
      },
    };
  },
  mounted() {},
  props: {
    value: Object,
  },
  methods: {
    add() {},
    onClick(row) {},
    // clickRow(row) {
    //   if (row.data()) {
    //     this.form.id = row.data().id;
    //     this.form.name = row.data().name;
    //     this.form.sku = row.data().sku;
    //     this.form.image = row.data().image;
    //     $("#exampleModal").modal("show");
    //   }
    // },
    getDetail(id) {
      if(id) {
          this.Api.get(`/product/${id}`).then(res =>{
              if(res.data) {
                  $('#exampleModal').modal('show');
                  this.form.id = res.data.id;
                  this.form.name = res.data.name;
                  this.form.sku = res.data.sku;
                  this.form.image = res.data.image;
                  this.isDetail = true
              }
          })
          .catch((e) =>{
              console.log(e)
          })  
      }
    },
    getEdit(id) {
      if(id) {
          this.Api.get(`/product/${id}`).then(res =>{
              if(res.data) {
                  $('#exampleModal').modal('show');
                  this.form.id = res.data.id;
                  this.form.name = res.data.name;
                  this.form.sku = res.data.sku;
                  this.form.image = res.data.image;
                  this.isDetail = false
              }
          })
          .catch((e) =>{
              console.log(e)
          })  
      }
    },
    showModal() {
      this.form.id = null;
      this.form.name = null;
      this.form.sku = null;
      this.form.image = null;
      $("#exampleModal").modal("show");
    },
    defaultBtnImage() {
      const defaultbtn = document.querySelector("#default-btn");
      defaultbtn.click();
    },
    showModalDelete(id) {
      $('#deleteModal').modal('show');
      this.form.id = id
    },
    async deleteData() { 
      this.loading = true
      if(this.form.id) {
        try { 
          await this.Api.delete(`/product/${this.form.id}`)
            this.loading = false,
            $('#deleteModal').modal('hide'),
            this.refresh()
        } catch(error) {
          alert(error.response.data.message)
          this.loading = false,
          $('#deleteModal').modal('hide'),
          this.refresh()
        }
      }
    },
    handleImage(e) {
        const selectedImage = e.target.files[0];
        if(selectedImage.type === "image/png" || selectedImage.type === "image/jpeg") {
          if(selectedImage.size <= 1024 * 1024) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.form.image = e.target.result;
            }
            reader.readAsDataURL(selectedImage);
          } else {
            alert('File too big (> 1MB)');
          }
        } else {
          alert('File is not supported')
        }
    },
    save() {
      this.loading = true;
      // if(!this.validationPassword()){
      //   this.loading = false
      //   return;
      // }
      if (this.form.id != null) {
        this.Api.put(`/product/${this.form.id}`, this.form)
          .then((res) => {
            this.loading = false;
            this.refresh();
            $("#exampleModal").modal("hide");
          })
          .catch((e) => {
            this.loading = false;
            this.error = e.response.data.message;
            this.text_validation = "Update Failed";
          });
      } else {
        this.Api.post("/product", this.form)
          .then((res) => {
            this.loading = false;
            this.refresh();
            $("#exampleModal").modal("hide");
          })
          .catch((e) => {
            this.loading = false;
            this.error = e.response.data.message;
            this.text_validation = "Submit Failed";     
          });
      }
    },
    refresh() {
      this.config = {
        url: this.Api.base + "/product",
      };
    },
  },
};
</script>
